<template>
  <v-container fluid class="pt-0">
    <page-options
      title="Inbox"
      tIcon="mdi-inbox"
      :allNames="allNames"
      style="position:fixed: top:0"
      :selectAll="[]"
      @refresh="refresh()"
      :page="options.page"
      :totalMails="totalCount"
      :mailsPerPage="options.itemsPerPage"
      @changePage="getInboxData($event, false)"
      @updateMails="resetShowMails()"
      @updatingMails="tableLoading = true"
    ></page-options>

    <v-card
      class="my-5"
      :class="tableLoading ? '' : 'glb-border-top'"
      :color="$store.getters.theme === 'dark' ? '#000000b3' : '#ffffffc6'"
      :dark="$store.getters.theme === 'dark' ? true : false"
    >
      <list-view
        :showMails="showMails"
        routePath="inbox"
        :userInfo="userInfo"
        :tableLoading="tableLoading"
        :options="options"
        @moveToArchive="moveToArchive"
        @moveToFolder="moveToFolder"
      ></list-view>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import listView from "./page-components/ListMails.vue";
import PageOptions from "./page-components/PageOptions.vue";
import { EventBus } from "@/plugins/event-bus";

export default {
  components: { listView, PageOptions },
  data() {
    return {
      options: { itemsPerPage: 50, page: 1 },

      showMails: [],
      tableLoading: false,
    };
  },
  beforeDestroy() {
    this.$store.dispatch("REFRESH_MAILS", "INBOX");
  },
  created() {
    EventBus.$on("inboxUpdated", this.resetShowMails);
    this.resetShowMails();
  },
  watch: {
    options() {
      this.getInboxData();
    },
  },
  methods: {
    getInboxData(pageNo) {
      this.options.page = pageNo;

      this.tableLoading = true;
      this.$store
        .dispatch("GET_FILTERED_INBOX_MAILS", {
          limit: this.options.itemsPerPage,
          pageNo: this.options.page,
        })
        .then(() => {
          this.tableLoading = false;
          this.resetShowMails();
        });
    },
    moveToArchive(archiveMail) {
      let index = this.totalMails.findIndex(
        (name) => name._id == archiveMail._id
      );

      this.totalMails.splice(index, 1);
      this.totalCount = -1;
      this.resetShowMails();

      this.$toast(`Moving this mail to Archive`);
      this.$store.dispatch("MAIL_TO_ARCHIVE", archiveMail);

      setTimeout(() => {
        this.$store.dispatch("UPDATE_MAIL", {
          operationCode: 1,
          emailObjectId: archiveMail._id,
        });
      }, 10);
    },
    moveToFolder(mail, tagName, folderName) {
      let index = this.totalMails.findIndex((name) => name._id == mail._id);

      this.totalMails.splice(index, 1);
      this.totalCount = -1;
      this.resetShowMails();

      this.$toast(`Moving this mail to ${folderName}`);
      this.$store.dispatch("MAIL_TO_FOLDER", { mail, tagName });

      setTimeout(() => {
        this.$store.dispatch("MOVE_FOLDER", {
          emailObjectId: mail._id,
          folderTag: tagName,
        });
      }, 10);
    },
    refresh() {
      this.$store.dispatch("REFRESH_MAILS", "INBOX");
    },
    resetShowMails() {
      this.tableLoading = false;

      this.showMails = this.totalMails.slice(
        this.options.page * this.options.itemsPerPage -
          this.options.itemsPerPage,
        this.options.page * this.options.itemsPerPage
      );
    },
  },
  computed: {
    allNames() {
      return this.userInfo.nickName
        ? [this.userInfo.nickName].concat(this.userInfo.domains)
        : [];
    },
    emptyMails() {
      return this.$store.getters.getInbox.length === 0 ? true : false;
    },
    userInfo() {
      return this.$store.getters.userInfo;
    },
    ...mapGetters({ totalMails: "getInbox" }),

    totalCount: {
      get: function () {
        return this.$store.getters.getInboxTotalMails;
      },
      set: function (value) {
        this.$store.commit("UPDATE_TOTAL_INBOX", value);
      },
    },
  },
};
</script>
